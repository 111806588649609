import React, { PureComponent } from "react";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock, loadImage } from '../gsap/timeline';
import { NavLink } from 'react-router-dom';
import LogoImage from '../assets/images/logo.svg';
import CarbonReductionPlanPDF from '../assets/images/CarbonReductionPlan.pdf';

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}
  
const Footer = class Footer extends PureComponent  {

    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% 0%'
        };
        
        const routeComponents = {
            '/' : '',
            'sq' :'/',
            'aviation': 'aviacion',
            'aviacion': 'aviation',
            'landing': 'landingalbanian',
            'landingalbanian': 'landing',
            'contact-us': 'kontakt',
            'kontakt': 'contact-us',
            'home': 'ballina',
            'ballina': 'home',
            'quality': 'kualiteti',
            'kualiteti': 'quality',
            'trading': 'tregetimi',
            'tregetimi': 'trading',
            'retail': 'retail-al',
            'retail-al': 'retail',
            'military-services': 'sherbimet-ushtarake',
            'sherbimet-ushtarake':'military-services',
            'supply': 'furnizimi',
            'furnizimi': 'supply',
            'location': "lokacioni",
            'lokacioni': "location",
        };
    
        let pathname = window.location.pathname;
        const showPDFLink = pathname.includes('/quality') || pathname.includes('/kualiteti')
        const isAlbanian = pathname.includes('/sq') ? true : false;
        
        if(isAlbanian && pathname==='/sq'){
            pathname = pathname.split('/')[1];
            pathname = routeComponents[pathname];
        }
        else if(isAlbanian) {
            pathname = pathname.split('/sq/')[1];
            pathname = '/' + routeComponents[pathname];
        }
        else if (isAlbanian===false && pathname === '/'  ) {
            pathname = routeComponents[pathname];
        }
        else {
            pathname = pathname.split('/')[1];
            pathname = '/' + routeComponents[pathname];
        }

        const domain = window.location.protocol + '//' + window.location.host;

        return (
            <Observer {...options}>
                <div className="footer gsap">
                    <div className="footer__blocks gsap-timeline ">
                        <div className="block social ">
                            <div className="container">
                                <div className="row remove--side-margins">
                                    <div className=" col-12 col-lg-8 remove--padding-left remove--padding-right social__top">
                                        <ul className="social__links">
                                            <li>
                                                <NavLink exact to={ isAlbanian ? '/sq/kontakt' : 'contact-us' }>
                                                    { isAlbanian ?  'Kontakt' : 'Contact Us' }
                                                </NavLink>
                                            </li>
                                            <li>
                                            <NavLink exact to={ isAlbanian ? '/sq/politika-kshsm' : 'qahsse-policy' }>
                                                { isAlbanian ?  'Politika KSHSM' : 'Legal Notice' }
                                            </NavLink>
                                            </li>   
                                            {/* <li>
                                            <NavLink
                                                exact
                                                to={isAlbanian ? "/sq/lokacioni" : "location"}
                                              >
                                                {isAlbanian ? "Lokacioni" : "Location"}
                                              </NavLink>   
                                            </li>   */}
                                            {showPDFLink &&
                                            <li>
                                                <a href={CarbonReductionPlanPDF} target="_blank" rel="noopener noreferrer">
                                                    Carbon Reduction Plan
                                                </a>
                                            </li>}
                                            <li className="language__switcher">
                                                <a className={ isAlbanian ? null : 'active' } href={ `${domain}${pathname}` }>EN /</a>
                                                <a className={ isAlbanian ? 'active' : null } href={ `${domain}/sq${pathname}`}>AL</a>
                                            </li>
                                    
                                        </ul>
                                    </div>
                                    <div className="col-lg-4 social__media remove--padding-left remove--padding-right">
                                        <ul className="social__icons flex flex-items-center">
                                            <li>
                                                <a href="https://www.facebook.com/exfisquality/" target="_blank" rel="noopener noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.59" height="27.986" viewBox="0 0 15.59 27.986">
                                                    <g transform="translate(-21.577 0.5)">
                                                        <path d="M36.118.006,32.618,0c-3.932,0-6.472,2.607-6.472,6.641V9.7H22.627a.55.55,0,0,0-.55.551v4.437a.55.55,0,0,0,.55.55h3.519V26.436a.55.55,0,0,0,.55.55h4.591a.55.55,0,0,0,.55-.55V15.241h4.114a.55.55,0,0,0,.55-.55l0-4.437a.551.551,0,0,0-.551-.551H31.837v-2.6c0-1.248.3-1.881,1.923-1.881h2.357a.55.55,0,0,0,.55-.55V.556A.55.55,0,0,0,36.118.006Z" fill="#d1d1d1" stroke="#144557" strokeWidth="1"/>
                                                    </g>
                                                </svg>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/exfis.official/?igshid=168lumdns45p6" target="_blank"  rel="noopener noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.32" height="22.319" viewBox="0 0 22.32 22.319">
                                                    <g>
                                                    <g>
                                                        <path d="M15.345,0H6.975A6.976,6.976,0,0,0,0,6.975v8.37A6.976,6.976,0,0,0,6.975,22.32h8.37a6.976,6.976,0,0,0,6.975-6.975V6.975A6.976,6.976,0,0,0,15.345,0Zm4.882,15.345a4.888,4.888,0,0,1-4.882,4.882H6.975a4.888,4.888,0,0,1-4.882-4.882V6.975A4.888,4.888,0,0,1,6.975,2.092h8.37a4.888,4.888,0,0,1,4.882,4.882Z" fill="#d1d1d1"/>
                                                    </g>
                                                    </g>
                                                    <g transform="translate(5.58 5.58)">
                                                    <g>
                                                        <path d="M133.58,128a5.58,5.58,0,1,0,5.58,5.58A5.58,5.58,0,0,0,133.58,128Zm0,9.067a3.487,3.487,0,1,1,3.487-3.487A3.492,3.492,0,0,1,133.58,137.067Z" transform="translate(-128 -128)" fill="#d1d1d1"/>
                                                    </g>
                                                    </g>
                                                    <g transform="translate(16.415 4.418)">
                                                    <g>
                                                        <circle cx="0.744" cy="0.744" r="0.744" fill="#d1d1d1"/>
                                                    </g>
                                                    </g>
                                                </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block copyright gsap-timeline">
                            <div className="container">
                                <div className="row remove--side-margins">
                                    <div className="col-lg-6 remove--padding-left remove--padding-right copyright__logo">
                                        <picture className="gsap-timeline" data-alt="copyright-logo">
                                            <source srcSet={ LogoImage } type="image/svg" />
                                        </picture>
                                    </div>
                                    <div className="col-12 col-lg-6 remove--padding-left remove--padding-right copyright__text gsap-timeline">
                                        <p>© Copyright 2020 ExFis. All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
}

export default React.memo(Footer)